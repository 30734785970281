<template>
	<div class="product">
		<Header :category-list="categoryList" :from="name" />
		<div class="container">
			<div class="top-box"></div>
			<div class="top-box2"></div>
			<div class="product-box">
				<div class="product-title flex-b">
					<p class="title-left">
						<span>产品中心</span>
						Product Center
					</p>
					<div class="product-search">
						<input
							type="text"
							class="input-search"
							v-model="searchProduct"
							placeholder="输入产品名称/产品编码进行查询"
						/>
						<button class="btn-search" @click="search"><i class="el-icon-search"></i></button>
					</div>
				</div>
				<div class="product-nav">
					<el-menu
						:default-active="defaultActive"
						class="menu-vertical"
						:unique-opened="true"
						@open="handleOpen"
						@close="handleClose"
					>
						<template v-for="menuList in categoryList">
							<el-submenu :index="menuList.firstCode" :key="menuList.firstCode" :id="menuList.firstCode">
								<template slot="title">
									<img class="menu-img" :src="menuList.firstUrl" />
									<span>{{ menuList.firstName }}({{ menuList.secondList.length }})</span>
								</template>
								<el-menu-item-group>
									<template v-for="menu in menuList.secondList">
										<el-menu-item @click="chooseOne(menu.secondCode)" :key="menu.secondCode" :index="menu.secondCode">
											{{ menu.secondName }}
										</el-menu-item>
									</template>
								</el-menu-item-group>
							</el-submenu>
						</template>
					</el-menu>
				</div>
				<div class="product-body flex-b">
					<div class="product-list">
						<div class="product-item" v-for="(item, index) in pageData" :key="index">
							<div class="item-img">
								<img :src="item.imageUrl" :width="item.imageWidth" :height="item.imageHeight" />
							</div>
							<div class="item-table">
								<el-table
									:data="item.productList"
									:header-cell-style="{ 'text-align': 'left', 'background-color': '#F7F9FA' }"
									style="width: 100%"
								>
									<el-table-column type="index" label="序号" align="left" min-width="6%"></el-table-column>
									<el-table-column prop="code" label="产品编号" align="left" min-width="34%"></el-table-column>
									<el-table-column
										prop="description"
										label="产品名称及规格"
										align="left"
										min-width="60%"
									></el-table-column>
								</el-table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fiexd-bottom">
				<div class="bottom-box">
					<div class="p-contianer">
						<el-pagination
							:hide-on-single-page="isShowPagination"
							:current-page="currentPage"
							:page-sizes="pageSizes"
							:page-size.sync="pageSize"
							:total="total"
							class="p-bar"
							layout="total, sizes, prev, pager, next, jumper"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
						/>
						<!-- <button class="p-button" @click="toCloud">
              <i class="el-icon-shopping-cart-full"></i>
              去云平台下单
            </button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header.vue'
	import { getFirstAndSecondCategoryUrl, getSearch, getItemList } from '@/api/common'

	export default {
		name: 'Product',
		components: {
			Header
		},
		data() {
			return {
				name: 'Product',
				searchProduct: '',
				defaultActive: '', // 默认打开菜单栏
				categoryList: [],
				pageData: [],
				isShowPagination: false, // 是否展示分页框
				currentPage: 1, // 当前页数
				pageSizes: [150],
				pageSize: 150,
				total: 0,
				chooseCode: '',
				isSearch: false,
				maxWidth: '1000',
				maxHeight: '1000'
			}
		},
		watch: {
			$route(to) {
				this.getActiveCode()
			}
		},
		created() {
			this.getCategory()
			// this.search()
		},
		methods: {
			getCategory() {
				getFirstAndSecondCategoryUrl().then(res => {
					this.categoryList = res.data.datas
					this.getActiveCode()
				})
			},
			getActiveCode(code) {
				if (this.$route.query.id) {
					this.defaultActive = this.$route.query.id
					this.chooseOne(this.defaultActive)
					if (this.$route.query.parentId) {
						console.log(this.$route.query.parentId, 123)
						setTimeout(() => {
							// document.body.scrollTop = this.$el.querySelector(`[id='${this.$route.query.parentId}']`).offsetTop

							document.querySelector("[id='96']").scrollIntoView({
								behavior: 'auto'
							})
						}, 100)

						setTimeout(() => {
							// document.body.scrollTop = this.$el.querySelector(`[id='${this.$route.query.parentId}']`).offsetTop

							document.querySelector(`[id='${this.$route.query.parentId}']`).scrollIntoView({
								behavior: 'smooth'
							})
						}, 800)
					}
				} else if (this.categoryList.length > 0) {
					this.defaultActive = this.categoryList[0].firstCode
					this.chooseOne('810003')
				}
				console.log(this.defaultActive)
			},
			search() {
				this.isSearch = true
				getSearch({
					q: this.searchProduct,
					sort: 1,
					page: this.currentPage - 1,
					pageSize: this.pageSize
				}).then(res => {
					this.pageData = res.data.datas
					this.handleImg()
					this.total = res.data.numberOfPages
					if (this.total <= this.pageSize) {
						this.isShowPagination = true
					}
				})
			},
			chooseOne(code) {
				this.isSearch = false
				this.chooseCode = code
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
				getItemList({
					type: 'category',
					text: '',
					key: '',
					sort: 1,
					categoryCode: this.chooseCode,
					categoryLevel: '2',
					pageNumber: this.currentPage - 1,
					pageSize: this.pageSize
				}).then(res => {
					this.pageData = res.data.datas
					this.handleImg()
					this.total = res.data.numberOfPages
					if (this.total <= this.pageSize) {
						this.isShowPagination = true
					}
				})
			},
			handleImg() {
				this.$nextTick(() => {
					for (let i = 0; i < this.pageData.length; i++) {
						const image = new Image()
						image.src = this.pageData[i].imageUrl
						image.onload = () => {
							const width = image.width
							const height = image.height
							if (width > this.maxWidth || height > this.maxHeight) {
								const ratio = Math.min(this.maxWidth / width, this.maxHeight / height)
								this.pageData[i].imageWidth = width * ratio
								this.pageData[i].imageHeight = height * ratio
							} else {
								this.pageData[i].imageWidth = width
								this.pageData[i].imageHeight = height
							}
						}
					}
				})
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath)
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath)
			},
			// 更改每页展示数量
			handleSizeChange(val) {
				this.pageSize = val
				if (this.isSearch) {
					this.search()
				} else {
					this.chooseOne(this.chooseCode)
				}
			},
			// 更改当前页数
			handleCurrentChange(val) {
				this.currentPage = val
				if (this.isSearch) {
					this.search()
				} else {
					this.chooseOne(this.chooseCode)
				}
			},
			toCloud() {
				window.open('https://www.jzsf.com/cloud/view/home/home.jsp', '_blank')
			}
		}
	}
</script>
<style scoped lang="less">
	// 主题色
	@primary-color: var(--primary-color);

	.product {
		width: 100%;
		background: #ffffff;

		.top-box {
			height: 72px;
		}

		.top-box2 {
			height: 108px;
		}

		.product-box {
			width: 1420px;
			position: relative;
			margin: auto;
			color: #2b2c33;

			.product-title {
				background-color: #fff;
				z-index: 20;
				width: 1420px;
				margin: 0 auto;
				height: 108px;
				align-items: center;
				position: fixed;
				top: 72px;
				left: 0;
				right: 0;

				p {
					font-size: 20px;
					font-weight: 400;
					line-height: 28px;

					span {
						margin-right: 8px;
						display: inline-block;
						font-weight: 500;
					}
				}

				.product-search {
					display: flex;
					align-items: center;

					.input-search {
						display: inline-block;
						width: 476px;
						height: 44px;
						margin-right: 16px;
						padding: 10px 24px;
						background: #f7f9fa;
						border-radius: 2px;
						border: none;
						outline: none;
					}

					.btn-search {
						display: inline-block;
						width: 78px;
						height: 42px;
						font-size: 20px;
						border: none;
						text-align: center;
						outline: none;
						border-radius: 2px;
						color: #fff;
						background: @primary-color;
						cursor: pointer;
					}
				}
			}

			.product-nav::-webkit-scrollbar {
				display: none;
			}

			.product-nav {
				width: 240px;
				height: 600px;
				overflow-y: scroll;
				padding-bottom: 108px;
				position: fixed;

				/deep/ .el-menu {
					border-right-color: transparent;
				}

				/deep/ .el-submenu {
					padding-bottom: 20px;
				}

				/deep/ .el-menu-item-group__title {
					display: none;
				}

				/deep/ .el-submenu__title {
					text-align: left;
					background: #f7f9fa;
				}

				/deep/ .el-menu-item {
					text-align: left;
					// 20(padding) + 20(img) + 12(margin)
					padding-left: 52px !important;
					background: #fff;
				}

				/deep/ .el-menu-item:hover {
					background: #e6f1fa;
				}

				/deep/ .el-menu-item.is-active {
					color: @primary-color;
					background: #e6f1fa;
				}

				.menu-img {
					width: 20px;
					height: 20px;
					margin-right: 12px;
				}
			}

			.product-body {
				width: 100%;
				padding-left: 264px;

				.product-list {
					flex: 1;
					margin-left: 24px;
					padding-bottom: 108px;

					.product-item {
						width: 100%;

						.item-img {
							padding: 32px 86px;
							border: 1px solid #ebebeb;
						}

						.item-table {
							width: 100%;
							padding: 32px 0;
						}
					}
				}
			}
		}

		.fiexd-bottom {
			position: fixed;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 108px;
			background-color: #fff;
			z-index: 99;

			.bottom-box {
				width: 1420px;
				margin: auto;

				.p-contianer {
					display: flex;
					align-items: center;
					justify-content: right;
					// 240(product-nav) + 24(product-list)
					margin-left: 264px;
					padding-top: 24px;

					.p-bar {
						flex: 1;
					}

					.p-button {
						display: inline-block;
						width: 148px;
						height: 36px;
						border: none;
						text-align: center;
						outline: none;
						border-radius: 2px;
						font-size: 14px;
						color: #fff;
						background: @primary-color;
						cursor: pointer;

						i {
							display: inline-block;
							margin-right: 8px;
						}
					}
				}
			}
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	@media screen and (max-width: 1550px) {
		.product {
			.product-title {
				background-color: #fff;
				z-index: 20;
				width: 1420px;
				margin: 0 auto;
				height: 108px;
				align-items: center;
				position: fixed;
				top: 60px !important;
				left: 0;
				right: 0;
			}
			.top-box {
				height: 72px;
			}

			.product-box {
				width: 1200px;
			}

			.fiexd-bottom {
				.bottom-box {
					width: 1200px;
				}
			}

			.product-list {
				flex: 1;
				margin-left: 0 !important;
        width: 100% !important;
				padding-bottom: 108px;
			}

			.product-title {
				background-color: #fff;
				z-index: 20;
				width: 1200px !important;
				margin: 0 auto;
				height: 108px;
				align-items: center;
				position: fixed;
				top: 60px;
				left: 0;
				right: 0;
			}
		}
	}

	@media screen and (max-width: 740px) {
		.product {
			.top-box {
				height: 60px;
			}

			.product-box {
				width: 100vw;

				.product-nav {
					width: 20vw;
				}
			}

			.fiexd-bottom {
				.bottom-box {
					width: 1200px;
				}
			}

			.product-title {
				background-color: #fff;
				z-index: 20;
				width: 80vw !important;
				margin: 0 !important;
				height: 108px;
				width: 100% !important;
				align-items: center;
				position: fixed;
				top: 60px !important;
				left: 0;
				right: 0;

				.title-left {
					text-align: center;
					font-size: 10px !important;
					width: 100px !important;
				}

				.input-search {
					width: 40vw !important;
					font-size: 10px !important;
					padding: 6px 10px !important;
				}
			}

			.product-nav {
				width: 100px !important;
				font-size: 10px !important;

				/deep/ .el-submenu__title {
					text-align: left;
					background: #f7f9fa;
					padding-left: 0 !important;
					font-size: 10px !important;
				}

				.menu-img {
					display: none;
				}

				.menu-vertical {
					position: relative;

					/deep/ .el-menu-item {
						padding-left: 0 !important;
						min-width: 100px !important;
						font-size: 10px !important;
					}
				}
			}

			.product-body {
				width: 100%;
				padding-left: 100px !important;
				padding-right: 2px !important;
			}
		}
	}
</style>
